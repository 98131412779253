<template>
  <div class="modal" style="display:block">

    <div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}">
        <div class="chat">
            <!-- question -->
            <p class="bulle-question">{{ current.libelle }}</p>

            <!-- <div v-if="hasError === true">
            <p><strong><big class="red">PAS TOUT A FAIT !</big></strong> <br>
            <span v-html="current.messages.erreur"></span></p>
            </div>
            <div v-if="hasError === false">
            <h2>BRAVO !</h2>
            <p><span v-html="current.messages.bravo"></span></p>
            </div> -->

            <!-- écrans de fin avec des images après un quiz, avant de passer à la suite -->
            <div v-if="showEcranFin !== null">
                <template v-for="ecranFin, index in current.ecransFin">
                    <template v-if="showEcranFin === index">
                        <p class="bulle-reponse" v-html="ecranFin.titre" :key="'p'+index"></p>
                        <!-- <div class="visuel" :key="index">
                            <img :src="ecranFin.image"
                            :alt="ecranFin.alt">
                        </div> -->
                    </template>
                </template>
            </div>

            <!-- REPONSES -->
            <template v-if="showEcranFin === null">
                <ul class="options" :class="current.class">
                    <li v-for="(reponse, index) in current.reponses" :key="index" :id="index">
                        <button v-if="reponse.libelle" type="button" class="btn defaut"
                            :disabled="hasError === false"
                            @click="clicRadio($event)">
                            {{ reponse.libelle }}
                        </button>
                    </li>
                </ul>
            </template>
        </div>
    </div>

    <!-- MODAL FOOTER -->
    <div class="modal-footer">
      <!-- BTN VALIDER -->
      <button v-if="hasError !== false" type="button" class="btn btn-blue align-center" @click="checkSelectedReponses()"><span>Valider</span></button>

      <!-- s'il y a des ecrans de fin à afficher -->
			<button v-if="hasError === false && (current.ecransFin !== undefined && (showEcranFin === null || showEcranFin < current.ecransFin.length -1))" type="button" class="btn btn-blue align-right" @click="afficheEcransFin()"><span>Suivant</span></button>

      <!-- BTN SUIVANT - AFFICHER UNE FOIS QUE LE USER A VALIDE CETTE ETAPE POUR PASSER A LA PROCHAINE -->
      <button v-if="hasError === false && (current.ecransFin === undefined || showEcranFin === current.ecransFin.length -1)" type="button" class="btn btn-blue align-right" @click="ecranSuivant()"><span>Suivant</span></button>
    </div>
  </div>
</template>


<script>
import jsonQuiz from '@/data/quiz.json'
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'QuizChat',
  data() {
    return {
      quizList: jsonQuiz,
      currentQuestion: 11, // 1er quiz de ce type
      hasError: null,
      nbBonnesReponsesSelected: 0,
      isAlreadyInDossier: false,
      isRight: true,
			showEcranFin: null,
    }
  },
	mounted() {
		if (this.options && this.options.currentQuestion) {
			this.currentQuestion = this.options.currentQuestion
		}
	},
  computed: {
    current() {
      return this.quizList[this.currentQuestion]
    },
    bonnesReponsesIds() {
      let bonnes_reponses = [];

      for (let i = 0; i < this.current.reponses.length; i++) {
        if (this.current.reponses[i].bonne_reponse) {
          bonnes_reponses.push(parseInt(i,10));
        }
      }

      return bonnes_reponses
    }
  },
	methods : {
		close() {
			this.$emit('close')
		},

    clicRadio(e) {
      let rep = e.target.parentNode
      let others_selected = document.querySelectorAll('.selected');

      for (let i = 0; i < others_selected.length; i++) {
        others_selected[i].classList.remove('selected')
      }
      rep.classList.add('selected')
    },

    checkSelectedReponses() {
      this.hasError = null

      // on parcourt les reponses selectionnees
      let selected = document.getElementsByClassName('selected')
      for (let j=(selected.length - 1); j>=0; j--) {

        // index de la reponse
        let rep_id = parseInt(selected[j].getAttribute('id'),10)

        // si la réponse sélectionnée est parmi les bonnes reponses
        if ((this.bonnesReponsesIds).indexOf(rep_id) !== -1) {
          selected[j].classList.add('right')
          selected[j].classList.remove('selected')
          this.nbBonnesReponsesSelected++
        } else {
          selected[j].classList.add('wrong')
          selected[j].classList.remove('selected')
        }
      }

      // s'il manque des bonnes réponses
      if (this.nbBonnesReponsesSelected != this.bonnesReponsesIds.length) {
        this.hasError = true
      } else {

        // toutes les bonnes réponses OK
        this.hasError = false

        // on reset la couleur des mauvaises reponses pour ne laisser que les bonnes reponses en vert
        let mauvaises_reponses = document.getElementsByClassName('wrong')
        for (let j=(mauvaises_reponses.length - 1); j>=0; j--) {
          mauvaises_reponses[j].classList.add('defaut')
          mauvaises_reponses[j].classList.remove('wrong')
        }
      }
    },

    // addToDossier() {
    //   this.$store.commit("addToDossier", {dossier: this.current.ajoutDossier.dossier, item: this.current.ajoutDossier.item})
    //   this.isAlreadyInDossier = true
    // },

		afficheEcransFin() {
			if (this.showEcranFin === null) {
				this.showEcranFin = 0
			} else {
				this.showEcranFin++
			}
		},

    ecranSuivant() {
      if (this.current.enchaineQuizIndex != undefined) {

        let nextQuiz = this.quizList[this.current.enchaineQuizIndex]

        if (nextQuiz.type !== this.current.type) {
          EventBus.$emit('changeQuiz', nextQuiz.type, nextQuiz.index)
        } else {

					// on reset les bonnes reponses avant de passer au quiz suivant
					let bonnes_reponses = document.getElementsByClassName('right')
					for (let j=(bonnes_reponses.length - 1); j>=0; j--) {
						bonnes_reponses[j].classList.add('defaut')
						bonnes_reponses[j].classList.remove('right')
					}
					this.nbBonnesReponsesSelected = 0
					this.hasError = null
					this.showEcranFin = null

					this.currentQuestion = this.current.enchaineQuizIndex
        }

      } else {
        this.close()
      }
    }
	}
}
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .modal {
        .bravo {
            border: none;
        }
    }

    .modal-content {
        padding: 0;
    }

    .chat {
        background: url('/img/bg-chat.png') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        position: relative;

        .bulle-question {
            background: #DDDDDD;
            color: black;
            font-size: 1.7rem;
            font-weight: 500;
            padding: 1.5rem 2rem;
            position: absolute;
            top: 15rem;
            left: 20rem;
            width: 50%;
            text-align: left;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-top: 2rem solid #DDDDDD;
                border-left: 2rem solid transparent;
                position: absolute;
                top: 0;
                left: -2rem;
            }
        }

        .bulle-reponse {
            background: $green;
            color: black;
            font-size: 1.7rem;
            font-weight: 500;
            padding: 1.5rem 2rem;
            position: absolute;
            top: 30rem;
            right: 6.5rem;
            min-width: 20%;
            text-align: left;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-top: 2rem solid $green;
                border-right: 2rem solid transparent;
                position: absolute;
                top: 0;
                right: -2rem;
            }
        }

        .options {
            background: #707070;
            display: flex;
            padding: 3rem;
            position: absolute;
            top: 30rem;
            right: 6.5rem;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-top: 2rem solid #707070;
                border-right: 2rem solid transparent;
                position: absolute;
                top: 0;
                right: -2rem;
            }

            li {
                margin-bottom: 0;
                position: relative;

                &:first-child {
                    margin-right: 5.5rem;

                    &:after {
                        content: "ou";
                        color: white;
                        font-weight: 500;
                        position: absolute;
                        right: -4rem;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                &.selected {
                    background: none;

                    .btn {
                        background: $blue;
                    }
                }

                &.right {
                    background: none;

                    .btn {
                        background: $green !important;
                    }
                }

                &.wrong {
                    background: none;

                    .btn {
                        background: $red;
                        color: white;
                    }
                }

                &.defaut {
                    border-color: transparent;
                }
            }

            .btn {
                height: 6.5rem;
                font-weight: 500;

                &.defaut {
                    background: #DDDDDD;

                    &:hover {
                        background: white;
                    }

                    &:active {
                        background: $blue;
                    }

                    @media screen and (max-width: 767px) {
                        &:focus {
                            background: $blue;
                        }
                    }
                }
            }
        }
    }
</style>